<template>
  <div>
    <b-row class="mb-1">
      <b-col>
        <div class="d-flex justify-content-between">
          <div><h4>ข้อมูลผู้เช่า</h4></div>
          <div>
            <b-dropdown variant="link" no-caret toggle-class="p-0" right>
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                >
                  <feather-icon icon="SettingsIcon" />
                </b-button>
              </template>

              <b-dropdown-item v-b-modal.modal-change-password>
                <feather-icon icon="CheckSquareIcon" size="16" />
                <span class="align-middle ml-50">เปลี่ยนรหัสผ่าน</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmDelete(member_id)">
                <feather-icon icon="TrashIcon" size="16" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <!-- :text="avatarText(userData.fullName)" -->
          <b-badge
            style="background-color: red !important"
            variant="danger"
            class="mb-2"
            v-if="data.blacklist === true"
            @click="OpenBlacklistModal(data.blacklist_id)"
          >
            <h1 class="pt-2 pb-2 text-white">BLACKLIST !!!!!!</h1>
          </b-badge>
          <div class="d-flex justify-content-start">
            <b-avatar
              src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png"
              variant=""
              size="104px"
              rounded
            />

            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h3 class="mb-0">{{ data.first_name }} {{ data.last_name }}</h3>
                <h3 class="mb-0">
                  {{ data.en_first_name }} {{ data.en_last_name }}
                </h3>

                <span class="card-text">
                  {{ data.email }}
                </span>
                <br />
                <span class="card-text" v-if="data.retro_username">
                  ( {{ data.retro_username }} )
                </span>
                <span class="card-text" v-else-if="data.gen_username">
                  ( {{ data.gen_username }} )
                </span>
              </div>
              <div class="d-flex flex-wrap" v-if="button">
                <b-button
                  variant="primary"
                  v-b-modal.modal-edit-member
                  size="sm"
                >
                  Edit
                </b-button>
              </div>
              <div class="d-flex flex-wrap" v-else>
                <b-button variant="success" @click="viewMember()" size="sm">
                  ดูข้อมูล
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="DollarSignIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ data.total_price_sum | number }} บาท</h5>
                <small>ยอดเช่าทั้งหมด</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="TrendingUpIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ data.orders_count }} ครั้ง</h5>
                <small>จำนวนครั้งที่เช่า</small>
              </div>
            </div>
          </div>
          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="DollarSignIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <!-- <h5 class="mb-0">213 วัน</h5> -->
                <h5 class="mb-0">{{ data.days_since_last_rental }}</h5>
                <small>เช่าล่าสุด</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="TrendingUpIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">{{ data.days_since_register }}</h5>
                <small>อายุสมาชิก</small>
              </div>
            </div>
          </div>
        </b-col>
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50">
                {{ data.role }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">เบอร์โทรศัพท์</span>
              </th>
              <td class="pb-50">
                {{ data.phone }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">อายุ</span>
              </th>
              <td class="pb-50 text-capitalize">{{ data.age }} ปี</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">เพศ</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ data.gender }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">เลขที่บัตรประชาชน</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ data.card_id }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">ที่อยู่ปัจจุบัน</span>
              </th>
              <td>
                <!-- {{ data.address1 }}x -->
                {{ data.full_address }}
              </td>
            </tr>

            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">ที่อยู่ตามบัตร</span>
              </th>
              <td>
                {{ data.id_full_address }}
              </td>
            </tr>

            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Note</span>
              </th>
              <td>
                {{ data.note_admin }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <black-list-check :member="data"></black-list-check>
        </b-col>
      </b-row>
    </b-card>
    <blacklist-view-modal
      :blacklist_id="data.blacklist_id"
    ></blacklist-view-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BlackListCheck from "../component/BlackListCheck.vue";
import {
  BBadge,
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import BlacklistViewModal from "./blacklist/BlacklistViewModal.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    BlackListCheck,
    BlacklistViewModal,
  },
  props: {
    button: {
      default: true,
      type: Boolean,
    },
    member_id: {
      type: String,
    },
  },
  watch: {
    member_id: function (newVal, oldVal) {
      this.member_id = newVal;
      this.LoadMember();
      return newVal;
    },
  },
  data() {
    return {
      product_id: this.$route.params.id,
      data: {},
      today: new Date(),
    };
  },
  mounted() {
    this.$root.$on("edit-member-complete", async () => {
      await this.LoadMember();
    });
  },
  created() {
    this.LoadMember();
  },
  methods: {
    viewMember() {
      window.open(`/member/view/${this.member_id}`, "_blank");
    },
    LoadMember() {
      if (this.member_id) {
        this.$http({
          method: "GET",
          url: `/member/view/${this.member_id}`,
        }).then((x) => {
          this.data = x.data.data;
          this.$emit("member_info", this.data);
        });
      }
    },
    OpenBlacklistModal() {
      this.$bvModal.show("modal-view-blacklist");
    },
    confirmDelete(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("ยืนยีนการลบสมาชิก !!!", {
          title: "ยืนยีนการลบสมาชิก",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http({
              method: "DELETE",
              url: `/member/${id}`,
            }).then((x) => {
              //    this.load_payment_history()
              this.$root.$emit("delete-member-complete");
              this.$router.push({ name: "member-list" });
            });
          }
        });
    },
    formatDuration(startDate) {
      startDate = new Date(startDate);
      const yearsDiff = this.today.getFullYear() - startDate.getFullYear();
      const monthsDiff = this.today.getMonth() - startDate.getMonth();
      const daysDiff = this.today.getDate() - startDate.getDate();

      let yearStr = "";
      let monthStr = "";
      let dayStr = "";

      if (yearsDiff > 0) {
        yearStr = `${yearsDiff} ปี `;
      }

      if (monthsDiff > 0) {
        monthStr = `${monthsDiff} เดือน `;
      }

      if (daysDiff > 0) {
        dayStr = `${daysDiff} วัน`;
      }

      return `${yearStr}${monthStr}${dayStr}`;
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
